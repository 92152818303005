<template>
  <div>
    <v-dialog
      v-model="modal"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="scale-transition"
      persistent
      scrollable
      max-width="900px"
    >
      <v-card outlined>
        <v-card-title class="subtitle-1 font-weight-bold"
          >{{
            isEdit
              ? $t("master_data.position.edit_position")
              : $t("master_data.position.add_position")
          }}
          <div style="position: absolute; right: 25px;">
            <v-icon :disabled="loading" @click="cancel">mdi-close</v-icon>
          </div>
        </v-card-title>
        <v-divider />
        <v-card-text style="height: 700px">
          <v-form ref="addPosition" lazy-validation @submit.prevent="submit">
            <v-row class="mt-6">
              <v-col
                v-for="(item, i) in dataPosition.position"
                :key="i"
                cols="12"
                sm="12"
                class="d-flex flex-column"
              >
                <v-text-field
                  v-model="dataPosition.position[i]"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('routes.position')"
                  ref="textPosition"
                  outlined
                  dense
                />

                <v-tabs v-model="tab" center-active show-arrows>
                  <v-tab v-for="route in routeAccess" :key="route.id">
                    {{ route.name }}
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <v-tab-item v-for="(route, i) in routeAccess" :key="route.id">
                    <v-card flat>
                      <v-treeview
                        v-model="route.kodes"
                        :items="[routeAccess[i]]"
                        open-all
                        item-key="kode"
                        item-disabled="locked"
                        dense
                        selectable
                        selected-color="primary"
                        open-on-click
                      />
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions class="mb-3 mr-3">
          <v-spacer />
          <v-btn outlined color="primary" @click="cancel">{{
            $t("app.cancel")
          }}</v-btn>
          <v-btn
            :disabled="!dataPosition.position"
            class="gradient-primary"
            dark
            depressed
            v-on:click="isEdit ? (confirmUpdate = true) : submit()"
            >{{ $t("app.save") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ModalConfirmUpdate
      :content="$t('master_data.position.confirm_update_content')"
      :close="() => (this.confirmUpdate = false)"
      :loading="loading"
      :isOpen="confirmUpdate"
      :save="submit"
    />
  </div>
</template>

<script>
export default {
  props: {
    modal: Boolean,
    isEdit: Boolean,
    dataPosition: Object,
    routeAccess: Array,
    loading: Boolean
  },
  watch: {
    modal(newValue) {
      this.tab = null;
      if (newValue && !this.isEdit) {
        this.resetForm();
      }
    }
  },
  components: {
    ModalConfirmUpdate: () => import("@/components/ModalConfirm")
  },
  data() {
    return {
      tab: null,
      valueFocus: false,
      confirmUpdate: false
    };
  },
  methods: {
    add() {
      this.$emit("addedPosition");
    },
    remove(index) {
      this.$emit("removePosition", index);
    },
    submit() {
      if (this.$refs.addPosition.validate()) {
        this.$emit("save");
        this.confirmUpdate = false;
      } else {
        return this.$refs.textPosition[0].focus();
      }
    },
    cancel() {
      this.resetForm();
      this.$emit("close");
    },
    resetForm() {
      this.$nextTick(() => {
        this.dataPosition.position = [""];
        this.$refs.addPosition.reset();
        this.$refs.addPosition.resetValidation();
      });
    }
  }
};
</script>
